<template>
    <div class="content">
      <div class="describe" v-if="dataMsg.cj">
        <div class="describeText">描述：3个月内有过进店做过护理、没有进店购买产品消费的顾客</div>
        <div class="dataMsg">
          <span>预警客户总数：{{dataMsg.allnum}}</span>
          <span>A类客户：{{dataMsg.cj.num}} | 占比：{{dataMsg.cj.zhanbi}}</span>
          <span>B类客户：{{dataMsg.yz.num}} | 占比：{{dataMsg.yz.zhanbi}}</span>
          <span>C类客户：{{dataMsg.pt.num}} | 占比：{{dataMsg.pt.zhanbi}}</span>
          <span>D类客户：{{dataMsg.zc.num}} | 占比：{{dataMsg.zc.zhanbi}}</span>
        </div>
      </div>
      <el-row class="bgW">
        <el-col :span="22">
          <!--检索条件-->
          <el-tag type="info">提示：注释注释注释注释注释注释注释注释注释注释注释注释注释注释注释注释</el-tag>
          <el-form class="search" :inline="true" size="mini" :model="search">
            <el-form-item label="预警日期">
              <el-date-picker
                v-model="search.warn_time"
                type="date"
                class="w130"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="回流日期">
              <el-date-picker
                v-model="search.back_time"
                type="date"
                class="w130"
                placeholder="选择日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
  
            <el-form-item label="门店/门诊" v-if="!userInfo.shop_id">
              <el-select
                v-model="search.shop_id"
                clearable
                remote
                filterable
                :remote-method="remoteMethod"
                placeholder="请输入"
                class="w120"
              >
                <el-option
                  v-for="item in options.shop_id"
                  :key="item.shopId"
                  :label="item.shopName"
                  :value="item.shopId"
                />
              </el-select>
            </el-form-item>
  

          </el-form>
        </el-col>
        <el-col :span="2" style="text-align: right;">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
        </el-col>
      </el-row>
      <div class="accountCon">
        <div class="btnBox">
          <el-button
            type="primary"
            class="mb10"
            size="mini"
            v-if="!userInfo.shop_id"
            @click="exportData('export')"
          >导出数据</el-button>
        </div>
        <!--列表-->
        <el-row>
          <el-col :span="24">
            <el-table
              :data="list"
              size="small"
              border
              :cell-style="$style.cellStyle"
              :header-cell-style="$style.rowClass"
              @selection-change="handleSelectionChange"
            >
              <ElTableColumn label="姓名" prop="customer_name" width="100"/>
              <ElTableColumn label="手机号" prop="telephone" width="100" />
              <ElTableColumn label="成交时间" width="150" prop="cj_time" />
              
              <ElTableColumn label="回访时间" prop="last_kftime"  width="150" />
              <ElTableColumn label="门店/门诊" prop="shop_name" width="150" />
              <ElTableColumn label="性别" width="50" prop="sex" />
              <ElTableColumn label="年龄" width="50" prop="age" />
              <ElTableColumn label="进店时间" width="120" prop="new_join_shop_time" />
              <ElTableColumn label="来源" prop="source_name" />
              <ElTableColumn label="会员等级" prop="grade_name" />
              <ElTableColumn label="护理师" prop="nurse_name" />
              
              <ElTableColumn label="客户分类" prop="class_name" />
              <ElTableColumn label="消费时间" prop="final_consumption"  width="150" />
              <ElTableColumn label="服务时间" prop="final_service"  width="150" />
              
              <ElTableColumn label="疤痕类型" width="170" prop="scar_aggregate" />
              <ElTableColumn label="消费金额" prop="consumption" />
              <ElTableColumn label="操作" fixed="right">
                <template slot-scope="{ row }">
                  <span class="theme" @click="tapBtn('客户详情',row.id)">查 看</span>
                </template>
              </ElTableColumn>
            </el-table>
            <pagination
              class="page tc mt10"
              :total="page.total"
              :page.sync="page.page"
              :limit.sync="page.limit"
              @pagination="getList"
            />
          </el-col>
        </el-row>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import pagination from "@/components/Pagination";
  import {
    clientListLS,
    clientList,
    ssettingList,
    statusSetting,
    getGradeList,
    clientExport
  } from "@/api/client";
  import { getShopList } from "@/api/shop";
  
  class Search {
    warn_time = "";
    back_time = "";
    shop_id = "";
  }
  class Options {
    sex = [
      { id: 0, name: "全部" },
      { id: 1, name: "男" },
      { id: 2, name: "女" }
    ];
    source_id = [];
    effective_entry = [
      { id: 0, name: "全部" },
      { id: 1, name: "是" },
      { id: 2, name: "否" }
    ];
    membership_leve = [];
    shop_id = [];
    member_classification = [];
    repair_progress = [];
    scar_id = [];
    consumption = [
      { id: 1, name: "大于等于" },
      { id: 2, name: "小于等于" },
      { id: 3, name: "等于" }
    ];
  }
  class Page {
    total = 0;
    page = 1;
    limit = 10;
  }
  export default {
    name: "LoseClient",
    components: {
      pagination
    },
    data() {
      return {
        search: new Search(),
        options: new Options(),
        page: new Page(),
        list: [],
        idList: "",
        dataMsg: {}
      };
    },
    filters: {
      statusFilter(val) {
        return ["启用", "禁用"][+val];
      }
    },
    computed: {
      ...mapGetters(["userInfo"])
    },
    mounted() {
      console.log(this.$route.query);
      if(this.$route.query){
        this.search.shop_id = this.$route.query.shop_id;
        this.search.warn_time = this.$route.query.start_time;
        this.search.back_time = this.$route.query.end_time;
      }
      console.log('离开');
      this.getList();
      this.getOptions();
    },
    methods: {
      // 获取选项
      getOptions() {
        ssettingList({ page: 1, limit: 100000 }).then(res => {
          let that = this;
          res.list.forEach(function(item) {
            if (item.category_id == 1) {
              that.options.scar_id.push(item);
            } else if (item.category_id == 4) {
              that.options.source_id.push(item);
            } else if (item.category_id == 5) {
              that.options.repair_progress.push(item);
            } else if (item.category_id == 8) {
              that.options.member_classification.push(item);
            }
          });
        });
        getGradeList().then(res => {
          this.options.membership_leve = res.list;
        });
      },
      // 获取列表
      getList(option) {
        console.log(this.$route.query.shop_id);
        console.log(this.search);
        
        if (option === "search") this.page = new Page();
        clientListLS({
           ...this.search,
           ...this.page,
           is_join_shop: 9 
        }).then(
          res => {
            this.list = res.list;
            this.dataMsg = res.statisticsData;
            this.page.total = res.dataCount;
          }
        );
      },
  
      // 导出数据
      exportData(option) {
        if (option === "export") this.page = new Page();
        clientListLS({ ...this.search, ...this.page, is_join_shop: 3 ,is_export:1 }).then(
          res => {
            // console.log(res);
            window.open(res.url);
          }
        );
      },
  
      // 搜索门店
      remoteMethod(e) {
        getShopList({ page: 1, limit: 100000, shopName: e }).then(res => {
          this.options.shop_id = res.list;
        });
      },
      // 跳转按钮
      tapBtn(routerName, id) {
        const query = { id: id, activeName: "first" };
        // this.$router.push({ name: routerName, query });
        let route = this.$router.resolve({ name: routerName, query });
        window.open(route.href, '_blank');
      },
      // 提示
      hint(text) {
        this.$confirm(`${text},是否确定？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.getExport();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });
      },
      // 导出
      getExport() {
        clientExport({ id: this.idList, type: 4 }).then(res => {
          window.open(res.data.url);
        });
      },
      // 改变表格选中状态
      handleSelectionChange(val) {
        this.idList = val.map(i => {
          return i.id;
        });
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .mainColor {
    color: #2dc0a3;
  }
  .content {
    height: calc(100% - 56px);
    overflow-y: auto;
  }
  .bgW {
    background: #fff;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .el-form-item {
    margin: 5px 20px 3px 0;
  }
  .accountCon {
    background: #fff;
    padding: 0px 10px 80px;
    .btnBox {
      display: flex;
      // justify-content: space-between;
    }
  }
  .describe {
    padding-left: 10px;
    .describeText {
      color: #666;
    }
    .dataMsg {
      line-height: 30px;
      span {
        margin-left: 0px;
        font-weight: bold;
      }
    }
  }
  </style>
  